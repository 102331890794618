<template>
  <div class="usercerti">
    <headbox title="实名认证" bgColor="#39394d" ></headbox>
    <!-- 用户基本信息 -->
    <div class="cerbox usercenter">
      <div class="tip">
        为保证您的账户安全，请先完成实名认证
      </div>
    <div class="radius">
      <h1>请绑定银行卡</h1>
        <cite>
        <p>真实姓名</p>
        <el-input
          placeholder="请输入真实姓名"
          v-model="formdata.name"
          disabled
        ></el-input>
      </cite>
      <cite>
        <p>身份证号</p>
        <el-input
          placeholder="请输入身份证号"
          v-model="formdata.idNum"
          disabled
        ></el-input>
      </cite>
      <cite>
        <p>银行卡号</p>

        <!-- suffix-icon="el-icon-camera" -->
        <el-input
          placeholder="请输入有效的银行卡号"
          v-model="formdata.bankNo"
          maxlength="19"
        >
          <van-uploader
            :after-read="afterRead"
            slot="suffix"
            :preview-image="false"
            ref="uploader">
            <div class="uploadIcon"></div>
          </van-uploader>
          <!-- <el-button class="btn_yellow" @click="postData">提交</el-button> -->
        </el-input>
        <!-- 上传加载 -->
        <div v-if="isloading">
          <div class="bgfilter"></div>
          <div class="tc loadingbox">
            <van-loading size="26px">
              <span class="loadingfont">上传中...</span>
            </van-loading>
          </div>
        </div>
      </cite>
      <cite>
        <p>银行名称</p>
        <el-input
          placeholder="请输入银行名称"
          v-model="formdata.bankName"
        ></el-input>
      </cite>
      <cite>
        <p>所属支行</p>
        <el-input
          placeholder="请输入支行名称"
          v-model="formdata.bankAddress"
        ></el-input>
      </cite>
      <cite>
        <div class="text">
          请填写银行卡信息,该信息主要用于资金发放,为保证及时性,请务必填写正确，且为本人银行账号。
        </div>
      </cite>
    </div>
      <div class="tc">
        <el-button class="btn_red" :style="{opacity: confirm?'1':'0.7'}" @click="postData" :disabled="!confirm">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import headbox from "@/components/head.vue";
import * as userDetails from "@/utils/api";
import BindPhoneModel from "./index";
import axios from "axios";
import * as certifi from "@/utils/api";

export default {
  data() {
    return {
      // 确认盒子点击时机
      confirm:false,
      // 是否上传中
      isloading: false,
      isloadingback: false,
      apiKey: "np5Z2o86jTLStdZxCnFPm6Hm",
      secretKey: "XBGA3SMlb2koPGMG4Xjo0zjEEFtxz64A",
      baidu_access_token: "",
      firstupsuccess: "",

      baiduBaseUrl:
          process.env.NODE_ENV == "dev" || process.env.NODE_ENV == "test" ? "/baidubce" : "https://api.shenzhenyht.com/baidubce",
      fileBaseUrl:
        process.env.NODE_ENV == "dev"
          ? "/shenzhenyht"
          : "https://yuechengjinye.oss-cn-shenzhen.aliyuncs.com",
      localtoken: localStorage.getItem("token"),

      formdata: {
        idCardBack: localStorage.getItem("back") || "", //身份证背面图片上传阿里云后的key
        idCardFace: localStorage.getItem("positive") || "", //身份证正面图片上传阿里云后的key
        name: "", //真实姓名
        bankNo: "", //银行卡号
        bankName: "", //银行名称
        bankAddress: "", //开户支行
        idNum: "", //身份证号码
        idExpire: "" //身份证过期时间
      }
    };
  },
  watch:{
    formdata:{
      handler(newVal,oldVal){
            console.log('newVal',newVal);
            if (newVal.name &&newVal.bankNo &&newVal.bankName  &&newVal.idNum &&newVal.bankAddress) {
                this.confirm  = true
            }else{
                this.confirm  = false

            }
      },
			deep: true
    }
  },
  mounted() {
    const getUser = JSON.parse(localStorage.getItem("idcrad"));
    const endData = JSON.parse(localStorage.getItem("endtime"));
    this.formdata.name = getUser.name;
    this.formdata.idNum = getUser.IdCardnumber;
    this.formdata.idExpire = endData.endtime;
    this.getAccess_token();
    console.log(this.baidu_access_token);
  },
  methods: {
    afterRead(file) {
      const than = this;
      if (this.isloading) {
        return false;
      }
      this.isloading = true;
      this.firstupsuccess = "";
      this.firstupsuccess = file.content;
      lrz(this.firstupsuccess, {
        quality: 0.2 //自定义使用压缩方式
      })
        .then(function(rst) {
          const data = {
            image: encodeURI(rst.base64).slice(22),
            id_card_side: "front"
          };
          // 获取文件上传地址
          console.log(this);
          than.getUploadUrl(rst.base64, "positive");
          than.baiduAi(data);
          //成功时执行
        })
        .catch(function(error) {
          this.isloading = false;
          console.log("error:" + error);

          //失败时执行
        });
    },

    base64ToUint8Array(base64String) {
      const base64clear = this.firstupsuccess.substring(
        this.firstupsuccess.indexOf(",") + 1
      );
      const padding = "=".repeat((4 - (base64clear.length % 4)) % 4);
      const base64 = (base64clear + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },

    //第一步（获取上传接口）
    getUploadUrl(path, type) {
      const that = this;

      const data = {
        size: 1
      };
      certifi.apiFile(data).then(res => {
        that.bankcardFace = res[0].key;
        // localStorage.setItem(type, that.bankcardFace);
        that.uploadFile(path, res[0].url);
      });
    },
    // 第二步上传文件
    uploadFile(imgpath, url) {
      // 去掉url域名 保留后面
      const urllast =
        "/" +
        url
          .split("/")
          .slice(3)
          .join("/");
      const configs = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + this.localtoken
        }
      };
      const transformRequest = [
        function(data) {
          let ret = "";
          for (const it in data) {
            ret +=
              encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
          }
          return ret;
        }
      ];
      const imgarr = this.base64ToUint8Array();
      axios({
        url: this.fileBaseUrl + urllast,
        method: "put",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: "Bearer " + this.localtoken
        },
        data: imgarr
      }).then(res => {
        console.log("请求结果：", res);
      });
    },

    // 百度获取API
    getAccess_token() {
      const that = this;
      // 百度识别API
      axios
        .post(
          `${this.baiduBaseUrl}/oauth/2.0/token?grant_type=client_credentials&client_id=np5Z2o86jTLStdZxCnFPm6Hm&client_secret=XBGA3SMlb2koPGMG4Xjo0zjEEFtxz64A`
        )
        .then(res => {
          const { access_token } = res.data;
          this.baidu_access_token = access_token;
          console.log(this.baidu_access_token);
        });
    },

    baiduAi(data) {
      const that = this;
      const token = that.baidu_access_token;
      const transformRequest = [
        function(data) {
          let ret = "";
          for (const it in data) {
            ret +=
              encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
          }
          return ret;
        }
      ];

      const configheaders = {
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      };
      axios
        .post(
          `https://aip.baidubce.com/baidubce/rest/2.0/ocr/v1/bankcard?access_token=` + token,
          data,
          { transformRequest }
        )
        .then(res => {
          // https://aip.baidubce.com/rest/2.0/ocr/v1/idcard
          console.log('res',res);
          if (
            res.data &&
            res.data.result &&
            res.data.result.bank_card_number.length > 0
          ) {
            that.formdata.bankNo = res.data.result.bank_card_number;
            that.formdata.bankName = res.data.result.bank_name;
            this.isloading = false;
            localStorage.setItem(
              "data",
              JSON.stringify({
                bankNo: that.formdata.bankNo,
                bankName: that.formdata.bankName
              })
            );
            // this.formdata.name = res.data.result.bank_card_number;
          } else {
            throw new Error("返回数据不合法");
          }
          // localStorage.setItem(
          //   "idcrad",
          //   JSON.stringify({ name: userName,Bankcardnumber: Bankcard })
          // );
        })
        .catch(error => {
          console.error(error);
          that.formdata.bankNo = "";
          that.isloading = false;
          that.$toast("图片上传错误");
          //  return that.$toast("图片上传错误"), !1;
        });
    },
    seedata() {
      this.$router.push({ name: "face" });
    },
    postData() {
      console.log(this.formdata);
      const bankReg = /[1-9]\d{12,18}/;
      if (this.formdata.bankNo === "") {
        this.$toast("银行卡卡号不能为空");
        return false;
      } else if (!bankReg.test(this.formdata.bankNo.replace(/\s*/g, ""))) {
        this.$toast("银行卡格式错误");
        return false;
      } else if (this.formdata.bankName === "") {
        this.$toast("银行名称不能为空");
        return false;
      }
      userDetails.authUser(this.formdata).then(async(res) => {
        if (res.code != 0) {
          this.$toast(res.msg);
        } else {
      //     localStorage.setItem('isSevrice',0)
      //     let user = await userDetails.getuser()
      //     localStorage.setItem('userinfo',JSON.stringify(user))
      //   //   初始化七鱼sdk
      //  this.fishkefu.init() ;
      //   // 打开七鱼客服接口

      //  this.qiyuInfo = user

      //   if(Object.prototype.toString.call(this.qiyuInfo)==='[object Object]'){
      //           this.fishkefu.Config(this.qiyuInfo)
      //           this.fishkefu.openSdk();
      //       }else{
      //           this.fishkefu.openSdk();
      //     }
          this.$router.push({
            name:'license'
          })
        }
      });
    }
  },
  components: {
    headbox
  }
};
</script>

<style lang="scss" scoped="true">
@import "@/assets/styles/index.sass";

::v-deep .el-input.is-disabled .el-input__inner {
  background: transparent;
  color: #303133;
}
.usercerti{
    height: 100%;
    background-color: #f5f5f5;
}
.detailsbox {
  color: #cccccc;
  font-size: 28px;
}
.titlebox {
  font-size: 36px;
  margin-bottom: 19px;
}
.checkbox {
  margin: 90px auto 40px;
  background: #20c88b;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  position: relative;

  i {
    position: absolute;
    left: 50%;
    top: 20%;
    font-size: 86px;
    color: #fff;
    transform: translate(-50%);
  }
}
::v-deep .el-input__inner {
  border: 0;
  padding: 35px 0;
  font-size: 32px;
  border-bottom: 1px solid #ccc;
}
::v-deep .el-input__suffix-inner {
  font-size: $font_Size32;
}
::v-deep .el-input__icon {
  margin: 50% 0;
}
::v-deep .van-uploader__upload {
  background: none;
}
::v-deep .van-uploader__upload-icon {
  font-size: 50px;
}
::v-deep i.van-icon.van-icon-photograph.van-uploader__upload-icon{
  color: #ff2d2e;
}
.cerbox {
  padding: 0 40px;
  p {
    font-size: 28px;
  }
  .radius{
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    padding: 20px;
    h1{
      font-size: 32px;
      font-weight: 500;
      color: #242424;
      line-height: 32px;
      margin-top: 20px;
    }
  }
  cite {
    display: block;
    margin-bottom: 30px;
    p{
      color: #666666;
    }
    .text{
      color: #999999;
      font-size: 28px;
    }
  }
  .usercenter {
    input {
      border: 0;
    }
  }
  .userbox {
    font-size: 32px;
    border-bottom: 1px solid #cccccc;
    i {
      font-style: normal;
    }
    b {
      margin-right: 40px;
      display: inline-block;
      padding: 30px 0;
      font-weight: normal;
      width: 150px;
    }
  }
  .imgboxlist {
    margin-bottom: 50px;
  }
  .bankcard {
    width: 90%;
  }
  .photobox {
    width: 20%;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%);
  }
  .tip {
    color: #b8b8b8;
    font-size: 24px;
    margin: 20px 0;
  }
}
h1 {
  font-size: 40px;
  margin-bottom: 40px;
  font-weight: normal;
}
.btn_red {

  margin-top: 80px;
  // background: #ffb606;
  background: $YellowFontColor;
  color: $YellowHeadBackgroundColor;
  border-radius: 50px;
  border: 0;
  width: 650px;
  height: 88px;
  font-size: 36px;
}
.uploadIcon{
  width: 50px;
  height: 50px;
  background: $YellowCameraBg;
  background-size: 100%;

}
</style>
